<template>
    <div id="claims">

        <b-modal size="lg" hide-footer ref="my-modal" title="Nouvelle Réclamation">
            <b-row>
                <b-col cols="12" md="12">
                    <b-form-group>
                        <label style="font-weight:bold">Message : </label>
                        <b-form-textarea id="textarea-state" ref="description" v-model="recText" :state="recText.length <= 400"
                            placeholder="Ne pas dépasser 400 caractères" rows="3" />
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row class="text-right">

                <b-col cols="12" md="12">
                    <b-button @click="reset_data" variant="gradient-warning" style="margin-right:5px">
                        Initialiser
                    </b-button>
                    <b-button @click="envoyer" :disabled="!validateForm" variant="gradient-success">
                        Réclamer
                    </b-button>
                </b-col>

            </b-row>

        </b-modal>

        <b-overlay :show="showLoading" spinner-variant="primary">
            <b-card>
                <ag-grid :agGrid="agGrid" pageTitle="Liste des réclamations" @refresh="loadData" :add="true"
                    @plusClicked="showModal" />
            </b-card>
        </b-overlay>

    </div>
</template>

<script>

import AgGrid from "@/views/components/ag-grid/AgGrid.vue"
import BadgeCellRenderer from "@/views/components/ag-grid/BadgeCellRenderer.vue";

export default {
    components: {
        AgGrid,
    },
    data() {
        return {
            recText: '',
            showLoading: false,
            agGrid: {
                columnDefs: [
                    {
                        headerName: "Date",
                        field: "Date",
                        // width: 60
                    },
                    {
                        headerName: "Type",
                        field: "Complaint_Type",
                        // width: 80,
                    },
                    {
                        headerName: "Motif",
                        field: "Reason_Description",
                        // width: 80,
                    },
                    {
                        headerName: "Réclamation",
                        field: "Wording",
                        // width: 200
                    },
                    {
                        headerName: "Traité par",
                        field: "Treated_Name",
                        // width: 80,
                    },
                    {
                        headerName: "Statut",
                        field: "Statut",
                        cellClass: 'centered-customeCellRender',
                        cellRendererFramework: 'badgeCellRenderer',
                        cellRendererParams: { componentName: "Claims" },
                        width: 90
                    },
                ],
                rows: []
            },
        }
    },
    computed: {
        validateForm() {
            return this.recText.trim() != '';
        },
    },
    created() {
        this.$options.components.badgeCellRenderer = BadgeCellRenderer;
        this.loadData();
    },
    methods: {
        reset_data() {
            this.recText = '';
        },
        async envoyer() {
            this.$refs['my-modal'].hide();
            this.showLoading = !this.showLoading;

            // const currentDate = new Date();
            // const year = currentDate.getFullYear();
            // const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Month is 0-based
            // const day = String(currentDate.getDate()).padStart(2, '0');
            // const dateString = `${year}-${month}-${day}`;
            let inputs = {
                // "Date": dateString,
                "Wording": this.recText.substring(0, 400),
                "Web_Complaint": true
            }
            let response = await this.$http.post("claim", inputs).catch(() => {
                this.showLoading = false
            });
            if (response.status == 200) {
                this.recText = '';
                this.$swal({
                    title: 'Réclamation envoyée avec succès .',
                    icon: 'success',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                    },
                    timer: 4000,
                    buttonsStyling: false,
                });
            }
            this.loadData();
        },
        showModal() {
            this.recText = '';
            this.$refs['my-modal'].show();
            setTimeout(() => {
                this.$refs["description"].focus();
            }, 400);
        },
        async loadData() {
            this.showLoading = true;
            let response = await this.$http.get("claim").catch(() => {
                this.showLoading = false;
            });
            this.agGrid.rows = response.data.reverse();
            this.showLoading = false;
        }
    }
}
</script>

<style lang="scss" scoped>
@media (max-width: 768px) {
    .card {
        background-color: initial;
        box-shadow: initial;
    }

    .card-body {
        padding: 0px;
    }
}
</style>